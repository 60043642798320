<template>
    <el-aside class="el-aside-menu">
        <div class="LeftMenu">
            <el-menu unique-opened
                     :default-active="menuActiveName || 'home'"
                     :collapse="sidebarFold"
                     :collapseTransition="false">
                <li class="menuCategory" v-if="!sidebarFold">{{ leftMenuCategory }}</li>
                <LeftSubmenu
                        v-for="menu in leftMenuList"
                        :key="menu.id"
                        :menu="menu"
                        :dynamicMenuRoutes="dynamicMenuRoutes">
                </LeftSubmenu>
            </el-menu>
        </div>
    </el-aside>
</template>

<script>
import LeftSubmenu from './LeftSubmenu'

export default {
    data() {
        return {
            dynamicMenuRoutes: []
        }
    },
    components: {
        LeftSubmenu
    },
    computed: {
        // 侧边栏显示隐藏
        sidebarFold: {
            get() {
                return this.$store.state.common.sidebarFold
            }
        },
        // 选中页签
        isTab: {
            get() {
                return this.$store.state.common.isTab
            }
        },
        // 菜单栏数据
        leftMenuList: {
            get() {
                return this.$store.state.common.leftMenuList
            },
            set(val) {
                this.$store.commit('common/updateLeftMenuList', val)
            }
        },
        // 菜单栏分类
        leftMenuCategory: {
            get() {
                return this.$store.state.common.leftMenuCategory
            },
            set(val) {
                this.$store.commit('common/updateLeftMenuCategory', val)
            }
        },
        // 当前菜单栏名称
        menuActiveName: {
            get() {
                return this.$store.state.common.menuActiveName
            },
            set(val) {
                this.$store.commit('common/updateMenuActiveName', val)
            }
        },
        // 页签数据
        mainTabs: {
            get() {
                return this.$store.state.common.mainTabs
            },
            set(val) {
                this.$store.commit('common/updateMainTabs', val)
            }
        },
        // 主题色
        defaultTheme() {
            return this.$store.state.config.defaultTheme
        },
        // 当前页签名称
        mainTabsActiveName: {
            get() {
                return this.$store.state.common.mainTabsActiveName
            },
            set(val) {
                this.$store.commit('common/updateMainTabsActiveName', val)
            }
        }
    },
    watch: {
        $route: 'routeHandle'
    },
    created() {
        this.dynamicMenuRoutes = JSON.parse(sessionStorage.getItem('dynamicMenuRoutes') || '[]')
        this.routeHandle(this.$route)
    },
    methods: {
        // 路由操作
        routeHandle(route) {
            if (this.isTab) {
                // tab选中, 不存在先添加
                let tab = this.mainTabs.filter(item => item.fullPath === route.fullPath)[0]
                if (!tab) {
                    if (route.meta.isDynamic) {
                        route = this.dynamicMenuRoutes.filter(item => item.name === route.name)[0]
                        if (!route) {
                            return console.error('未能找到可用标签页!')
                        }
                    }
                    tab = {
                        menuId: route.meta.menuId || route.name,
                        name: route.name,
                        title: this.$router.currentRoute.query.title || route.meta.title,
                        type: route.meta.type,
                        iframeUrl: route.meta.iframeUrl || '',
                        query: this.$router.currentRoute.query,
                        parmas: this.$router.currentRoute.parmas,
                        fullPath: this.$router.currentRoute.fullPath
                    }
                    this.mainTabs = this.mainTabs.concat(tab)
                }
                tab.title = this.$router.currentRoute.query.title || route.meta.title
                this.menuActiveName = tab.menuId + ''
                this.mainTabsActiveName = tab.fullPath
                let topMenuActiveIndex = route.meta.parentIds && route.meta.parentIds.split(',').length > 2 ? route.meta.parentIds.split(',')[2] : '0'
                this.$store.commit('common/updateTopMenuActiveIndex', topMenuActiveIndex)
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.LeftMenu {
  background: url('~@/assets/img/leftMenuBack.png') no-repeat !important;
  background-size: 100% 100% !important;
  &::v-deep .el-menu {
    background: none !important;
      .el-submenu__title,.el-menu-item{
          color: #FFFFFF !important;
      }
      .el-submenu__title:hover,.el-menu-item:hover,.el-menu-item.is-active {
      background: #BC2124 !important;
    }
  }
}
</style>
